import React from "react"

function ShortDescription({ text, maxLength = 50 }) {
  let suffix = ""
  if (text.length > maxLength) {
    suffix = "..."
  }
  return <p>{text.substring(0, maxLength) + suffix}</p>
}

export default ShortDescription
