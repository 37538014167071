import * as React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../layout"
import Seo from "../components/seo"
import Panel from "../components/panel"
import PanelTitle from "../components/panel/title"
import PanelContent from "../components/panel/content"
import meta from "../meta-constants"
import ShortDescription from "../components/short-description"
import { navigate } from "gatsby-link"

const IndexPage = () => {
  const cardClass = "index__tool-cards col-12 col-sm-6 col-md-4"
  return (
    <Layout>
      <Seo title="Online Tools! - Free online conversion, calculation and data manipulation utilities" />
      <h2 style={{ fontSize: "18px" }}>Welcome to Online Tools!</h2>
      <p>
        We provide free to use utilities that are useful for everyone and
        required quite often. Right from image conversion tools to numeric
        operations. We have a lot of tools designed, check out below to find
        what suits your need.
      </p>
      <br />
      <h2>Encoders</h2>
      <div className="row">
        <div
          className={cardClass}
          role="link"
          onClick={() => navigate(meta.base32encoder.link)}
        >
          <Panel>
            <PanelTitle titleText={meta.base32encoder.title} />
            <PanelContent>
              <p>{meta.base32encoder.description}</p>
            </PanelContent>
          </Panel>
        </div>
        <div
          className={cardClass}
          role="link"
          onClick={() => navigate(meta.base64encoder.link)}
        >
          <Panel>
            <PanelTitle titleText={meta.base64encoder.title} />
            <PanelContent>
              <p>{meta.base64encoder.description}</p>
            </PanelContent>
          </Panel>
        </div>
        <div
          className={cardClass}
          role="link"
          onClick={() => navigate(meta.urlencoder.link)}
        >
          <Panel>
            <PanelTitle titleText={meta.urlencoder.title} />
            <PanelContent>
              <p>{meta.urlencoder.description}</p>
            </PanelContent>
          </Panel>
        </div>
      </div>

      <br />
      <h2>Decoders</h2>
      <div className="row">
        <div
          className={cardClass}
          role="link"
          onClick={() => navigate(meta.base32decoder.link)}
        >
          <Panel>
            <PanelTitle titleText={meta.base32decoder.title} />
            <PanelContent>
              <p>{meta.base32decoder.description}</p>
            </PanelContent>
          </Panel>
        </div>
        <div
          className={cardClass}
          role="link"
          onClick={() => navigate(meta.base64decoder.link)}
        >
          <Panel>
            <PanelTitle titleText={meta.base64decoder.title} />
            <PanelContent>
              <p>{meta.base64decoder.description}</p>
            </PanelContent>
          </Panel>
        </div>
        <div
          className={cardClass}
          role="link"
          onClick={() => navigate(meta.urldecoder.link)}
        >
          <Panel>
            <PanelTitle titleText={meta.urldecoder.title} />
            <PanelContent>
              <p>{meta.urldecoder.description}</p>
            </PanelContent>
          </Panel>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
